import ButtonLink from "core/components/ButtonLink";
import { Row } from "core/styles";
import styled from "styled-components";

import { getOrganizations, getUsers } from "modules/admin/actions";
import useCurrentUser from "core/hooks/useCurrentUser";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useEffect } from "react";
import { REVERSE_LOOKUP_TABLEAU_ROLES } from "core/constants";
import Typography from "core/components/Typography";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-top: 125px;
  padding-left: 50px;
  padding-right: 50px;

  background-image: url(/home-arrows-normal.svg);
  background-repeat: no-repeat;
  background-size: 100vw auto;
  background-position: center bottom;

  @media only screen and (max-width: 1400px) {
    padding-top: 75px;
    background-image: url(/home-arrows-narrow.svg);
    background-size: 100vw auto;
    background-position: center bottom;
  }

  @media only screen and (min-width: 2000px) {
    padding-top: 0vh;
    /* height: 100vh; */
    background-image: url(/home-arrows-wide.svg);
  }
`;

const HomeColumns = styled.div`
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
  max-width: 1400px;
  align-items: flex-start;

  @media only screen and (max-width: 1400px) {
    max-width: 700px;
    flex-direction: column;
  }

  @media only screen and (min-width: 2000px) {
    max-width: 100%;
    margin-top: 25vh;
  }
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  flex-wrap: wrap;
  flex: 1 1 350px;

  @media only screen and (max-width: 1400px) {
    margin-top: 0px;
    flex: 0 1 auto;
  }

  @media only screen and (min-width: 2000px) {
    max-width: 470px;
    margin-top: 0px;
  }
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  @media only screen and (min-width: 2000px) {
    max-width: 100%;
    flex-direction: row;
  }

  /* flex: 0 1 auto; */
  /* flex-shrink: 1; */

  /* @media only screen and (min-width: 1400px) {
    padding-right: 80px;
  } */
`;

const Card = styled(Row)`
  padding-left: 38px;
  padding-right: 25px;
  min-height: 295px;
  max-width: 690px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
`;

const StyledHeaderPrimary = styled(Typography)`
  font-size: 60px;
  line-height: 60px;
  font-weight: 600;
`;

const StyledHeaderSecondary = styled(Typography)`
  font-size: 36px;
  line-height: 60px;
`;

const Home = () => {
  // return <div>This is home</div>;
  const {
    currentUser: { organizationId, email },
  } = useCurrentUser();

  const fetchOrgQueryCall = useAuthenticatedCall(getOrganizations);
  const { data: organizations } = useQuery({
    queryKey: ["organizations", organizationId],
    queryFn: fetchOrgQueryCall,
    staleTime: 20 * 1000,
  });

  const fetchUsersQueryCall = useAuthenticatedCall(getUsers);
  const { data: users } = useQuery({
    queryKey: ["users", organizationId, { email }],
    queryFn: fetchUsersQueryCall,
    staleTime: 20 * 1000,
  });

  const user = useMemo(() => users?.[0], [users]);
  const hasBiPortalLicense = useMemo(
    () =>
      user?.tableauConfig?.tableauLicense &&
      Object.values(REVERSE_LOOKUP_TABLEAU_ROLES).includes(
        user?.tableauConfig?.tableauLicense
      ),
    [user]
  );

  const organization = useMemo(() => organizations?.[0], [organizations]);
  const biPortalUrl = useMemo(
    () => organization?.onboarding?.bi?.portal_url,
    [organization]
  );

  const biPortal = useMemo(
    () =>
      user && organization // user and org has loaded
        ? hasBiPortalLicense && biPortalUrl
          ? {
              // valid bi portal link and user license
              disabled: false,
              errorMessage: null,
            }
          : {
              // invalid
              disabled: true,
              errorMessage: !hasBiPortalLicense
                ? "You do not have a BI Portal license assigned." // no BI licence for user
                : "The BI Portal is not set up for your organization.", // no BI portal link for org
            }
        : {
            // user and/or org not loaded yet, disable button
            disabled: true,
            errorMessage: null,
          },
    [user, organization, hasBiPortalLicense, biPortalUrl]
  );

  useEffect(() => {
    window.history.pushState({ from: "Home" }, "");
  }, []);

  return (
    <Container>
      <HomeColumns>
        <TitleColumn>
          <StyledHeaderSecondary variant="h5" color="text-blue" noMargin>
            Welcome to the
          </StyledHeaderSecondary>
          <StyledHeaderPrimary variant="h1" color="text-blue" noMargin>
            Definitive Healthcare Platform
          </StyledHeaderPrimary>
        </TitleColumn>

        <CardColumn>
          <Card>
            <img
              src="/bi-portal-graphic.png"
              alt="BI Portal"
              style={{ height: "240px", width: "198.19px" }}
            />
            <div>
              <Typography variant="h1">BI Portal</Typography>
              <Typography variant="p">
                Our BI Portal provides ready-to-use dashboards for Market,
                Provider, and Population Intelligence. You can access a variety
                of dashboards across Population, Market, and Provider
                Intelligence, based on your organization’s subscription.
              </Typography>

              <Row style={{ gap: "5px" }}>
                <ButtonLink
                  as="a"
                  width="fit-content"
                  disabled={biPortal.disabled}
                  href={biPortal.disabled ? null : biPortalUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Launch My Portal
                </ButtonLink>
                {biPortal.errorMessage ? (
                  <Typography variant="sub-text">
                    {biPortal.errorMessage}
                  </Typography>
                ) : null}
              </Row>
            </div>
          </Card>
          <Card>
            <img
              src="/support-graphic.png"
              alt="Support"
              style={{ height: "228px", width: "189px" }}
            />
            <div>
              <Typography variant="h1">Success Portal</Typography>
              <Typography variant="p">
                Our Support Portal provides valuable end-user documentation,
                including data dictionaries, product overviews, training
                content, support articles, and release notes. You can also use
                the Support Portal to submit questions or issues.
              </Typography>
              <ButtonLink
                as="a"
                width="fit-content"
                href="https://success.populi.ai/hc/en-us"
                target="_blank"
                rel="noreferrer"
              >
                Get Support
              </ButtonLink>
            </div>
          </Card>
        </CardColumn>
      </HomeColumns>
    </Container>
  );
};

export default Home;
